<template>
    <!-- style="max-width: 1100px; margin: auto; font-size: 1.5rem;" -->
  <div style="margin: auto; font-size: 1.5rem;" class="grey lighten-3" >
  <v-app>

    <v-flex xs12>
    <v-toolbar color="pink" dark >

      <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->
      <v-toolbar-title><router-link to="/">简历</router-link> <small class="black--text">由 AI 辅助翻译自 </small><small><router-link to="/en">https://i.ch2n.com/#/en</router-link></small></v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>search</v-icon>
      </v-btn> -->
    </v-toolbar>
    </v-flex>

    <v-card>
      <v-container fluid grid-list-lg >

        <v-layout row wrap>

          <v-flex xs12>

            <v-card color="blue-grey darken-2" class="white--text">

              <v-layout row style="word-break: break-word;">

                <v-flex>
                  <v-card-title primary-title>
                    <div>
                      <div class="title font-weight-medium mb-1" >陈淦</div>
                      <div>8 年经验</div>
                      <div>i@ch2n.com</div>
                      <div>+86-131-2287-9925</div>
                    </div>
                  </v-card-title>
                </v-flex>

                    <!-- src="https://i.loli.net/2020/05/31/NCe6DnU2xoQa4dY.jpg" -->
                <v-contianer align-end>
                <v-flex mt-1 mr-2>
                  <v-img
                  class="align-end mt-2"
                    src="../assets/Untitled.png"
                    height="145px"
                    width="145px"
                    contain
                  ></v-img>
                </v-flex>
                </v-contianer>

              </v-layout>

              <v-divider light></v-divider>

              <v-card-actions class="pa-3">
                <v-layout row>
                  <v-flex>
                    <div>擅长 Linux、Python、Kubernetes、Splunk 和 GCP 的 SRE</div>
                  </v-flex>
                  <!-- <v-flex class="xs5">
                    <div>Shanghai Polytechnic University</div>
                  </v-flex> -->
                  <!-- <v-flex class="xs4">
                    <div>majored in Electronic Commerce</div>
                  </v-flex> -->
                </v-layout>
              </v-card-actions>

            </v-card>
          </v-flex>


          <v-flex xs12>
            <v-card  style="word-break: break-word;">
            
              <v-card-title primary-title>
                <div style="line-height: 1.35 !important">

                <v-flex class="mb-0 pb-0 mt-0 pt-0">
                  <h3 class="headline mb-0 pb-0 blue--text">概况</h3>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - 性格: </span> 儒学启蒙，传统内敛，宽容随和。做事上则细致深入，思维缜密，有主人公思维，愿意为上下游多做一分。
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Linux/Scripting: </span>  Mac/Windows/Linux power user/commandline hero,
                      能够按照 <a class="blue-grey--text text--lighten-1" href="https://www.linuxfromscratch.org/" target="_blank">Linux From Scratch</a> 构建最小的 Linux 发行版。
                      但对内核各子系统和 eBPF 尚无深入研究。Python/Bash 熟悉, Java/Go 了解。喜欢尝试新技术，本简历在线版本即为 Vue APP (https://i.ch2n.com)。
                      这是我最近在工作中编写的一个<span class="font-weight-bold">示例脚本</span>(已脱敏): 
                      <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/python/get_alert_triggers/src/main.py" target="_blank">https://gitee.com/x2c/tmp/blob/main/python/get_alert_triggers/src/main.py</a>
                      
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Splunk 专家: </span> 在 Splunk logging 方面拥有多年的专业知识，无论是集群管理员还是高级用户角色。
                      能够从零开始建立集群，管理所有组件的大量配置，开发扩展 APP，创建交互式 dashboard，并在海量数据中撰写<span class="font-weight-bold">高效和精确的 Splunk 查询</span>。
                      以下是我的一个演讲中提到的一些 <span class="font-weight-bold">Splunk 查询技巧</span>: <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/splunk/query_tips.md" target="_blank">https://gitee.com/x2c/tmp/blob/main/splunk/query_tips.md</a>
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Kubernetes/云:</span> 对容器化, 服务网格和微服务有较好的理解，多年 k8s/Istio 生产经验, 熟悉程度 GCP/AWS > Azure > 阿里云..., IAM、VPC、GCE、GAE、GKE/EKS、GCS/S3、Pub/Sub、Azure DevOps 等实际使用经验。
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - 优秀的排错能力:</span> 用户行为层(亿级用户超大规模环境), 网络层(dns, ip/tcp, tls, mtls, certificate, http, istio, container, grpc), 服务依赖层(Kafka, workflow engine), 或者 codebase, CI/CD, infra-as-code, configuration-as-code 层面。
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold blue-grey--text text--lighten-2" style="font-size: 1.6rem;">
                   - 我的限制:</span> 相对有限的开发经验, <a class="blue-grey--text text--lighten-1" href="https://cert.efset.org/yY3Goy" target="_blank">中级水平的英语</a>, 口头沟通弱于书面, 有时花费过高代价追究根因与原理。
                  </div>
                </v-flex>

                </div>
              </v-card-title>
            </v-card>
          </v-flex>


          <v-flex xs12>
            <v-card  style="word-break: break-word;">
              <v-card-title primary-title body-2>

                <div style="line-height: 1.35 !important">

                <v-flex class="mb-0 pb-0 mt-0 pt-0">
                  <h3 class="headline mb-0 pb-0 blue--text">工作经历</h3>
                </v-flex>

                <v-flex>
                    <div class="mb-1">
                      <span class="font-weight-bold deep-purple--text text--darken-4" style="font-size: 1.6rem;">Site Reliability Engineer <a class="font-weight-bold blue-grey--text text--darken-2" href="https://www.quest-global.com/" target="_blank">Apple Pay (AWF)</a> 2022.12 - 2024.09</span>
                    </div>
                    <div class="mb-1"> - Quest Global 的 lead engineer，外派至 Apple Pay APAC SRE team 作为 onsite vendor，在顶级用户规模和及其优秀的工程质量环境中工作。
                    </div>
                    <div class="mb-1">
                      - 很大一部分职责是作为二线 oncall 定位生产问题以及判断故障范围，追踪微服务复杂网络环境下跨内部外部多个合作伙伴的复杂用户行为，依赖最多的是 metric/logging 系统。多次成功发现隐蔽的业务错误, 及时止损, 避免不为人知的可观损失。
                    </div>
                    <div class="mb-1">
                      - 第二部分是利用脚本来分析或处理各种内部系统的数据，大多数情况下这依然是处理生产问题的一部分，有时则是一些<span class="font-weight-bold">工具性质</span>(例如: <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/python/gen_aws_kube_config/src/app/aws_profiles.py" target="_blank">https://gitee.com/x2c/tmp/blob/main/python/gen_aws_kube_config/src/app/aws_profiles.py</a>)。
                    </div>
                    <div class="mb-1">
                      - 除生产问题外，还需要为 CI/CD 链条上所有相关服务和配置排除故障及补丁式 fix, 对 lower env 提供有限的支持。
                    </div>
                </v-flex>

                <v-flex >
                    <div class="mb-1">
                    <span class="font-weight-bold deep-purple--text text--darken-4 mb1" style="font-size: 1.6rem;">Site Reliability Engineer <a class="font-weight-bold blue-grey--text text--darken-2" href="https://stubhub.com" target="_blank">Stubhub Shanghai Imformation Technology Ltd</a> 2020.8 - 2022.8</span>
                    </div>
                    <div class="mb-1"> - 拥有约 1，000 名员工的行业领先公司。
                      由于与母公司 eBay 分离，我们需要将所有服务从 eBay 的数据中心迁移到 GCP。
                      我负责 Splunk 服务，成功将 Splunk 集群<span class="font-weight-bold">无缝过渡到 GKE</span> (Kubernetes managed by Google Cloud)，持续为整个组织提供稳定的日志记录、警报和 dashbord 功能。
                      日数据量最高为 5TB, 大于 1k 个客户端。
                      </div>
                    <div class="mb-1"> 
                      - 此外，还需要协同管理维护所有其他内部支撑系统，实现各系统间的集成, puppet/terraform/ansible/jenkins/nexus/自建 yum 源/自建 CICD 平台, nginx + spring boot + react + spring batch + oracle + kafka + solr + nfs, Jira, Slack, NewRelic, PagerDuty...
                    </div>

                    <div class="mb-1"> 
                      - 离职原因：被收购乃至平台整合完成后，买家裁员所有人，只保留品牌由其自有人员运营，新闻 <a href="https://www.ticketnews.com/2022/08/stubhub-closing-san-francisco-shanghai-offices-laying-off-staff">StubHub Closing San Francisco, Shanghai Offices, Laying Off Staff</a>
                    </div>

                </v-flex>

                <v-flex >
                    <div class="mb-1">
                    <span class="font-weight-bold deep-purple--text text--darken-4 mb1" style="font-size: 1.6rem;">
                      Operations Engineer <a class="font-weight-bold blue-grey--text text--darken-2" href="https://techown.com" target="_blank">Shanghai Techown Imformation Technology Ltd</a> 2019.4 - 2020.8</span>
                    </div>
                    <div class="mb-1">
                      - 一家拥有约 100 名员工的小型公司。
                        为了解决数据处理不流畅和告警缺失的痛点， 我从零开始构建了一个 Splunk 集群，<span class="font-weight-bold">创建了 30 多个交互式仪表板和许多告警来报告关键业务指标，
                        为钉钉警报开发了第三方应用程序，编写了多个脚本和程序</span>来满足客户需求并优化整个数据处理工作流程。这是一个描述这个过程的 slides: <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/slides/A_slide.key" target="_blank">https://gitee.com/x2c/tmp/blob/main/slides/A_slide.key</a> 
                    </div>

                    <div class="mb-1">
                      - 这是我制作的一个<span class="font-weight-bold">示例 dashboard</span>: <a class="blue--text text--darken-1" href="https://i.loli.net/2020/07/13/1sqizeWZkE35cFB.pn" target="_blank">https://i.loli.net/2020/07/13/1sqizeWZkE35cFB.png</a>
                    </div>

                    <div class="mb-1">
                      - 离职原因：疫情之下公司裁员，虽然不在被裁之列，但允诺的涨薪未兑现，另外负责的系统已稳定运行，长达几个月缺乏动力继续成长
                    </div>

                </v-flex>

                <v-flex >
                    <div class="mb-1">
                    <span class="font-weight-bold deep-purple--text text--darken-4 mb5" style="font-size: 1.6rem;">
                      Operations Engineer 
                      <a class="font-weight-bold blue-grey--text text--darken-2" href="https://www.i8xiaoshi.com" target="_blank">several </a> 
                      <a class="font-weight-bold blue-grey--text text--darken-2" href="http://www.houhao.cn" target="_blank">modestly </a> 
                      <a class="font-weight-bold blue-grey--text text--darken-2" href="http://www.cloudvsp.com/" target="_blank">sized </a> 
                      <a class="font-weight-bold blue-grey--text text--darken-2" href="http://www.lnuo.com.cn/" target="_blank">companies</a> 2016.3 - 2019.4</span>
                    </div>
                    <div class="mb-1">
                      - 职业生涯的头三年里，我辗转于几家规模不大的公司，总是独立工作。
                        这些工作大多属于传统运维工程师的范畴，包括主机和服务管理。下面介绍一些<span class="font-weight-bold">主要成就</span>。
                    </div>

                    <div class="mb-1">
                      - 针对一个 SaaS Web 产品，通过将服务迁移到 docker 并在更少的虚拟机中运行各个组件， 包括 nodejs、redis、mongo、mysql、java 和 .Net 服务， 
                        实现了阿里云<span class="font-weight-bold">成本降低 30%</span>。
                        还将 <span class="font-weight-bold">HA 功能引入到生产中</span>: nginx+keepalived LB、keepalived + redis(master slave+sentinel)、mongo(replica set)、mysql(dual master)，
                        将 Deployment Runbook 从手动迁移到 shell 迁移到 ansible 迁移到 docker。
                    </div>

                    <div class="mb-1">
                      - 在一个初创团队，<span class="font-weight-bold">从零开始建立整个办公室 IT 基础设施</span>，包括硬件和软件。
                        管理路由器、交换机、服务器、服务器机架、电话交换系统、固定电话和笔记本电脑的选型， 采购和配置。
                        设计网络，<span class="font-weight-bold">配置 Windows 域</span>， 包含 WDS+MDT、CIFS、WSUS、CA 和 VPN。
                        <span class="font-weight-bold">配置 pfSense </span>来管理 IDC 中服务器的网络，建立与办公网络的站点到站点 OpenVPN 连接，<span class="font-weight-bold">实施 Zabbix </span>进行全面的操作系统和硬件监控。
                    </div>

                </v-flex>
              
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex xs12>
            <v-card color="cyan darken-2" class="white--text ">
              <v-card-title primary-title body-2>
                <div class="d-flex headline mb-1">教育</div>
                <div>
                      <div class="mb-1"> - 2012 - 2016，上海第二工业大学，电子商务专业</div>
                      <div class="mb-1"> - 大多数课程都是关于利用 Java 构建电子商务网站，采用的是一些现在看起来过时的技术，例如 Java 1.6、Spring MVC、Struts2 和 Hibernate。</div>
                      <div class="mb-1"> - 我对探索那些<span class="font-weight-bold text--darken-2">看起来很骇客</span>的知识有着浓厚的兴趣，
                        例如构建 100MB 以下的最小 Windows 映像，使用包括 Kali 在内的 Linux 桌面，
                        使用仅包含几个开机启动进程的最小 Android ROM，以及 iOS 越狱等等。
                      </div>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3">
                <div>
                  非常感谢您花时间阅读这份简历，它的在线版本为 <a href="https://i.ch2n.com/#/en">https://i.ch2n.com/</a>
                </div>
                <v-spacer></v-spacer>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
              </v-card-actions>
            </v-card>
          </v-flex>

        </v-layout>

      </v-container>
    </v-card>

  </v-app>
  </div>
</template>

<script>
export default {
  name: 'En',
  components: {
  }
}

</script>

<style scoped>
a {
  color: #424242;
}
body {
    /*en,zh,mac,windows,linux,ios,android*/
    font: 14px/1.5 Arial,Helvetica,"Lucida Grande","Helvetica Neue",'PingFang SC',"Hiragino Sans GB","Hiragino Sans GB W3","Microsoft YaHei","WenQuanYi Microhei","Heiti SC","STHeiti","Noto Sans CJK SC","Source Han Sans CN",sans-serif; 
}
</style>
